import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';

import Teaching from 'components/Teaching';

const TeachingPage: React.FC = () => (
  <>
    <Layout>
      <SEO title="Teaching" />
      <Teaching />
    </Layout>
  </>
);

export default TeachingPage;
