import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import InfoBlock from 'components/ui/InfoBlock';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';


import * as Styled from './styles';

import { FcReading } from "react-icons/fc";

const Teaching = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "teaching section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "teaching" } } }, sort: { fields: fileAbsolutePath }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              icon
              cover {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const sectionTitle = markdownRemark.frontmatter;
  const services = allMarkdownRemark.edges;

  return (
    <Container section>
      {/*<TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} center />*/}
      <TitleSection title={sectionTitle.title} icon={<FcReading style={{width: '32px', height: '32px'}}/>} center />
      <Styled.Services>
        {services.map((item) => {
          const {
            id,
            html,
            // frontmatter: { title, icon, cover }
            frontmatter: { title, cover }
          } = item.node;

          return (
            <Styled.ServiceItem key={id}>
              <InfoBlock  title={title} image={cover} content={<FormatHtml content={html} />} center/>
            </Styled.ServiceItem>
          );
        })}
      </Styled.Services>
    </Container>
  );
};

export default Teaching;
